import { FedopsStore, FedopsConfig, MuteFunc } from '@wix/thunderbolt-symbols'
import { createFedopsLogger } from '@wix/thunderbolt-commons'
import { factory } from '@wix/web-bi-logger'
import { FedopsLogger } from '@wix/fedops-logger'

const clientLoggerFactory = (biStore: FedopsStore, muteFunc: MuteFunc) => {
	const uoUContext = {
		msid: biStore.msid,
		visitorId: biStore.viewerSessionId,
		siteMemberId: () => biStore.siteMemberId,
	}

	return (
		factory({ useBatch: true })
			.setMuted(muteFunc())
			// @ts-ignore TODO open a PR to web-bi-logger to fix types FEDINF-1629
			.withUoUContext(uoUContext)
			.updateDefaults({
				vsi: biStore.viewerSessionId,
				dc: biStore.dc,
				ish: biStore.is_headless,
				is_cached: biStore.isCached,
				is_sav_rollout: biStore.is_sav_rollout,
				is_dac_rollout: biStore.is_dac_rollout,
				is_rollout: biStore.is_rollout,
				session_id: biStore.sessionId,
				request_id: biStore.requestId,
			})
	)
}

export const fedopsCreator = ({
	biStore,
	config,
	shouldMute,
}: {
	biStore: FedopsStore
	config?: Partial<FedopsConfig>
	shouldMute?: boolean
}): FedopsLogger => {
	const biLoggerFactory = clientLoggerFactory(biStore, () => shouldMute || false)
	return createFedopsLogger({ biLoggerFactory, ...config })
}
